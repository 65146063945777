.main__wrapper {
    display: flex;
    margin: 2px 4px;
    padding: 52px 24px;
    height: fit-content;
    min-height: 100%;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

.main__sub {
  font-size: 17px;
  color: #898989;
}

.ant-collapse-header {
  align-items: center !important;
}