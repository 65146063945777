.bg{
  background-image: url('../../assets/images/public-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}

.center {
  background-color: #ffffff;
  padding: 12px;
  display: flex;
  justify-content: center;
}

.logo{
  width: 150px;
}

#loginform{
  width: 80%;
}