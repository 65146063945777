.moduleWrapper {
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: #d7e6f050;
}
.documentRow {
    padding:4px 4px;
    border-bottom: 3px solid #898989;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin:8px 0px;
    width: calc(50% - 24px);
}
.documentRow_marmi {
    padding:4px 4px;
    border-bottom: 3px solid #898989;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin:8px 0px;
    width: calc(100% - 24px);
}